import React from 'react';
import styled from '@emotion/styled';
import Axios from 'axios';

import PageLayout from '../components/PageLayout';
import Article from '../components/Articles/Article';
import { useStickyPosts } from '../queries/usePosts';
import { WP_API_URL } from '../constants';
import ArticleList from '../components/Articles/ArticleList';
import { ContentMain, device } from '../components/commonStyled';
import { useBlogPageData } from '../queries/useBlogPageData';

async function fetchPosts({ page }) {
    const res = await Axios.get(
        `${WP_API_URL}/posts?page=${page}&per_page=4&posts&_fields[]=title&_fields[]=excerpt&_fields[]=featured_media&_fields[]=slug`
    );
    const posts = res.data;
    const lastPage = +res.headers['x-wp-totalpages'];

    return { items: posts, lastPage };
}

const ContentMainStyled = styled(ContentMain)`
    max-width: 1230px;
`;

const ARTICLE_TITLE = 'Статьи';

export default function Posts({ location }) {
    const mainPosts = useStickyPosts();
    const { seo } = useBlogPageData();

    return (
        <PageLayout
            breadcrumbs={[
                {
                    url: location?.pathname as string,
                    title: ARTICLE_TITLE,
                },
            ]}
            title={ARTICLE_TITLE}
            seoMeta={seo}
        >
            <ContentMainStyled>
                <StickyArticles>
                    {mainPosts[0] && <Article item={mainPosts[0]} />}
                    {mainPosts[1] && <Article item={mainPosts[1]} />}
                </StickyArticles>
                <ArticleList getItems={fetchPosts} />
            </ContentMainStyled>
        </PageLayout>
    );
}

const StickyArticles = styled.div`
    display: flex;
    margin-bottom: 83px;

    @media ${device.tabletMedium} {
        flex-direction: column;
    }
`;
