import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import useSWR from 'swr';

import { FONT_GOTHAM_MEDIUM } from '../../styles/fonts';
import { device, RoundedButtonWithArrow } from '../commonStyled';
import { WP_API_URL } from '../../constants';
import { getWebpImageUrl } from '../../utils';

const CourseCardContainer = styled.div`
    flex-shrink: 0;
    width: 344px;

    @media ${device.laptopSmall} {
        margin-bottom: 36px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
`;

const Description = styled.div`
    color: #2e0f25;
    font-size: 18px;
    line-height: 26px;
    flex-grow: 1;
    margin: 24px 0;

    a {
        display: none;
    }

    @media ${device.laptopSmall} {
        font-size: 16px;
        text-align: center;
    }
`;

const ArticleTitle = styled.div`
    color: #000000;
    font-family: GothamPro;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    font-family: ${FONT_GOTHAM_MEDIUM};

    @media ${device.laptopSmall} {
        font-size: 20px;
        text-align: center;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 50px;

    @media ${device.laptopSmall} {
        margin-left: 24px;
        align-items: center;
        margin-left: 0;
    }
`;

const ArticleListItemContainer = styled(Link)`
    width: 100%;
    display: flex;
    padding-top: 32px;
    padding-bottom: 42px;
    border-bottom: 1px solid rgba(209, 209, 209, 0.5);
    transition: all 0.5s ease-in-out;

    &:first-child {
        padding-top: 0;
    }

    @media ${device.laptopSmall} {
        flex-direction: column;
        align-items: center;
        padding-bottom: 42px;
    }
`;

const ImageContainer = styled('div')<{ isLoading: boolean }>`
    background-color: rgb(247, 240, 237);
    width: 344px;
    height: 281px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: rgba(25, 27, 26, 0.1) 0px 1px 2px 0px,
        rgba(25, 27, 26, 0.08) 0px 1px 8px 0px;

    transition: filter 0.5s ease;

    filter: blur(${({ isLoading }) => (isLoading ? 5 : 0)}px);
`;

const ImageStyled = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

interface ArticleProps {
    imageId: number;
    title: string;
    url: string;
    description: string;
}

const ArticleListItem: React.FC<ArticleProps> = ({
    imageId,
    title,
    url,
    description,
}) => {
    const { data } = useSWR(
        `${WP_API_URL}/media/${imageId}?_fields[]=media_details&_fields[]=alt_text`
    );

    const sizes = data?.media_details?.sizes;
    const imageFull = sizes?.medium?.source_url;

    return (
        <ArticleListItemContainer to={url}>
            <CourseCardContainer>
                <ImageContainer isLoading={!imageFull}>
                    {data && (
                        <ImageStyled
                            src={imageFull}
                            loading="lazy"
                            alt={data?.alt_text}
                        />
                    )}
                </ImageContainer>
            </CourseCardContainer>
            <InfoContainer>
                <ArticleTitle dangerouslySetInnerHTML={{ __html: title }} />
                <Description
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                <ButtonContainer>
                    <RoundedButtonWithArrow as="div">
                        Читать далее
                    </RoundedButtonWithArrow>
                </ButtonContainer>
            </InfoContainer>
        </ArticleListItemContainer>
    );
};

export default ArticleListItem;
