import React from 'react';

import Spinner from './Spinner';

interface LazyLoadProps<Response> {
    getItems: ({ page: number }) => Promise<Response>;
    onLoad: (res: Response) => void;
    offsetLoad?: number;
}

interface LazyLoadState {
    isLoading: boolean;
    canLoadMore: boolean;
}

export class LazyLoad<Response> extends React.Component<
    LazyLoadProps<Response>,
    LazyLoadState
> {
    private pageCounter = 0;

    private containerRef = React.createRef<HTMLDivElement>();

    state: LazyLoadState = {
        isLoading: false,
        canLoadMore: true,
    };

    static defaultProps = {
        offsetLoad: 500,
    };

    private incPageCounter = (): number => {
        this.pageCounter += 1;
        window['nemkova-site-page-counter'] = this.pageCounter;

        return this.pageCounter;
    };

    private loadNextPage = () => {
        const bottom =
            this.containerRef.current &&
            this.containerRef.current.getBoundingClientRect().bottom;
        if (
            bottom &&
            !this.state.isLoading &&
            window.innerHeight > bottom - this.props.offsetLoad
        ) {
            this.loadPage(this.incPageCounter());
        }
    };

    private loadPage = async (page: number) => {
        if (this.state.canLoadMore) {
            this.setState({
                isLoading: true,
            });

            try {
                const response = await this.props.getItems({ page });
                this.props.onLoad(response);
                this.setState({
                    isLoading: false,
                });
                if ((response as any).lastPage === page) {
                    this.setState({
                        canLoadMore: false,
                    });
                    window.removeEventListener('scroll', this.loadNextPage);
                }
            } catch (e) {
                this.setState({
                    canLoadMore: false,
                    isLoading: false,
                });
                window.removeEventListener('scroll', this.loadNextPage);
            }
        }
    };

    public componentDidMount(): void {
        this.pageCounter = window['nemkova-site-page-counter'] || 0;
        this.loadNextPage();
        window.addEventListener('scroll', this.loadNextPage, { passive: true });
    }

    public componentWillUnmount(): void {
        window.removeEventListener('scroll', this.loadNextPage);
    }

    render() {
        const { isLoading } = this.state;

        return (
            <div ref={this.containerRef}>
                {this.props.children}
                {isLoading && <Spinner />}
            </div>
        );
    }
}
