import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { PostResponse, PaginationList } from '../../models';
import { LazyLoad } from '../LazyLoad';
import { ROUTES } from '../../constants';
import { useBlogPageData } from '../../queries/useBlogPageData';
import { CompatibleImg } from '../Img';
import { device } from '../commonStyled';

import ArticleListItem from './ArticleListItem';

interface ArticleListProps {
    getItems: (o: { page: number }) => Promise<PaginationList<PostResponse>>;
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ArtilceContainer = styled.div`
    width: 77%;

    @media ${device.mobileBig} {
        width: 100%;
    }
`;

const BannerContainer = styled(Link)`
    width: 23%;
    padding-left: 50px;

    @media ${device.mobileBig} {
        display: none;
    }
`;

const CompatibleImgStyled = styled(CompatibleImg)`
    position: sticky !important;
    top: 100px;

    @media ${device.laptopMedium} {
        top: 90px;
    }
`;

const BannerMobileContainer = styled(Link)`
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    width: 100%;

    display: none;

    @media ${device.mobileBig} {
        display: block;
    }
`;

const ArticleList: React.FC<ArticleListProps> = ({ getItems }) => {
    const [posts, setPosts] = useState<PostResponse[]>([]);

    useEffect(() => {
        if (window['nemkova-site-posts']) {
            setPosts(window['nemkova-site-posts'] || []);
        }
    }, []);

    const { banner_main, banner_main_mobile } = useBlogPageData();

    const onLoad = (res: PaginationList<PostResponse>) => {
        const newPosts = [...posts, ...res.items];
        res && res.items?.length && setPosts(newPosts);
        window['nemkova-site-posts'] = newPosts;
    };

    return (
        <Container>
            <ArtilceContainer>
                <LazyLoad<PaginationList<PostResponse>>
                    getItems={getItems}
                    onLoad={onLoad}
                >
                    <div>
                        {Boolean(posts.length) &&
                            posts?.map(
                                ({
                                    title: { rendered: titleText },
                                    slug,
                                    excerpt: { rendered: description },
                                    featured_media,
                                }) => {
                                    return (
                                        <ArticleListItem
                                            key={slug}
                                            imageId={featured_media}
                                            description={description}
                                            title={titleText}
                                            url={`/${ROUTES.articles}/${slug}`}
                                        />
                                    );
                                }
                            )}
                    </div>
                </LazyLoad>
            </ArtilceContainer>
            {Boolean(posts.length) && (
                <BannerContainer to={`/${ROUTES.freeProgramms}`}>
                    <CompatibleImgStyled
                        image={banner_main.localFile}
                        alt="Бесплатные программы"
                    />
                </BannerContainer>
            )}
            <BannerMobileContainer to={`/${ROUTES.freeProgramms}`}>
                <CompatibleImg
                    image={banner_main_mobile.localFile}
                    alt="Бесплатные программы"
                />
            </BannerMobileContainer>
        </Container>
    );
};

export default ArticleList;
